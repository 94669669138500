import axios from "axios";
import {
    ADMIN_LOGIN,
} from "../../actions/type";
import { API_URL, localStoragePrefix } from "../../../utils/config";

// check admin authenticated or not by id and token
export const checkAdminAuthentication = async (payload, callBack) => {
    try {
        let data = JSON.stringify({
            id: payload?.id,
            access_token: payload?.access_token,
            role: payload?.role,
        });

        let config = {
            maxBodyLength: Infinity,
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.post(
            `${API_URL}/admin/users/get-admin`,
            data,
            config
        );
        if (res?.data?.status === true) {
            callBack("success", res?.data);
        } else {
            callBack("failed", res);
        }
    } catch (error) {
        const message = error?.response?.data;
        callBack("failed", message);
    }
};


// login admin by email and password
export const authLoginAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                email: payload?.email,
                password: payload?.password,
                role: "1",
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/users/login`,
                data,
                config
            );
            if (res?.data?.status === true) {
                const authToken =
                    res?.data?.data?.access_token && res?.data?.data?.access_token;
                const userId = res?.data?.data?.id && res?.data?.data?.id;
                const userRole = Number(res?.data?.data?.role) && Number(res?.data?.data?.role);
                localStorage.setItem(localStoragePrefix + "screenGeniusAuthToken", authToken);
                localStorage.setItem(localStoragePrefix + "screenGeniusUserID", userId);
                localStorage.setItem(localStoragePrefix + "screenGeniusUserRole", Number(userRole));
                dispatch({
                    type: ADMIN_LOGIN,
                    payload: res?.data,
                });
                callBack("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.data;
            callBack("fail", message);
        }
    };
};