import React from "react";
import StatusCard from "./StatusCard";

const StatusReports = () => {
    return (
        <div className="extension-reports mb-4">
            <div className="status-report-card card">
                <div className="card-header">
                    <h5 className="m-0 p-2">Server Response Analysis</h5>
                </div>
                <div className="card-body">
                    <div className="status-outer-row">
                        <StatusCard
                            status={200}
                            message={"Success"}
                            statusClass={"success-card"}
                        />  
                        <StatusCard
                            status={300}
                            message={"Multiple Choices"}
                            statusClass={"multi-choise-card"}
                        />
                        <StatusCard
                            status={400}
                            message={"Bad Request"}
                            statusClass={"bad-reqest-card"}
                        />
                        <StatusCard
                            status={404}
                            message={"Not Found"}
                            statusClass={"not-found-card"}
                        />
                        <StatusCard
                            status={500}
                            message={"Internal Server Error"}
                            statusClass={"internet-server-card"}
                        />
                        <StatusCard
                            status={503}
                            message={"Service Unavailable"}
                            statusClass={"unavailable-card"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusReports;
