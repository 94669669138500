import axios from "axios";
import { API_URL, localStoragePrefix } from "../../utils/config";
import {
  GET_PROGRESS,
  LOGIN,
  SIGN_UP,
  Get_USER_DETAIL_BY_ID,
  EDIT_USER_DETAIL,
} from "../actions/type";

// withCredentials
axios.withCredentials = false;

export const loginUser = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        email: payload?.email,
        password: payload?.password,
        name: payload?.name,
        picture: payload?.picture,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          // "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/user/login`, data, config);
      if (res?.data?.status === true) {
        let authToken =
          res?.data?.data?.access_token && res?.data?.data?.access_token;
        let userId = res?.data?.data?.id && res?.data?.data?.id;
        let userRole =
          Number(res?.data?.data?.role) && Number(res?.data?.data?.role);
        localStorage.setItem(
          localStoragePrefix + "screenGeniusAuthToken",
          authToken
        );
        localStorage.setItem(localStoragePrefix + "screenGeniusUserID", userId);
        localStorage.setItem(
          localStoragePrefix + "screenGeniusUserRole",
          Number(userRole)
        );
        dispatch({
          type: LOGIN,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      console.log(error, "error==>");
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

// login with linked in
export const loginWithLinkedIn = async(code, callBack) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      };

      const res = await axios.post(`${API_URL}/user/linkedin/${code}`, config);
      
      if (res?.data?.status) {
        callBack('success', res?.data);
      } else {
        callBack('failed', res?.data);
      }
    } catch (error) {
      callBack('failed', error);
    }
};

// create user account
export const createUserAccount = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        name: payload?.name,
        email: payload?.email,
        password: payload?.password,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`${API_URL}/user/signup`, data, config);

      if (res?.data?.status === true) {
        dispatch({
          type: LOGIN,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

// forget user password
export const forgetPassword = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        email: payload?.email,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/user/forget-password`,
        data,
        config
      );

      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

// verify user email
export const verifyEmailToken = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios
        .post(`${API_URL}/user/verify/email?token=${payload?.token}`, config)
        .then((result) => {
          callBack("success", result);
        })
        .catch((err) => {
          callBack("failed", err?.response);
        });
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

// verify user email
export const resendVerifyEmailToken = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/user/resend-token?token=${payload?.token}`,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

// user create new password
export const createNewPassword = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        resetToken: payload?.token,
        password: payload?.password,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/user/change-password`,
        data,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

export const manageUserProgress = (userID, authToken, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        access_token: authToken,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/screenshots/progress/${userID}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        dispatch({
          type: GET_PROGRESS,
          payload: res?.data,
        });
        callBack("success", res?.data);
      } else if (res?.data?.status === false) {
        callBack("failed", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

export const getUserDetailsByID = (authToken, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        access_token: authToken,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          // "Access-Control-Allow-Credentials": "true",
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/user/get`, data, config);
      if (res?.data?.status === true) {
        dispatch({
          type: Get_USER_DETAIL_BY_ID,
          payload: res?.data,
        });
        callBack(res?.data, "success");
      } else if (res?.data?.status === false) {
        callBack(res?.data, "fail");
      }
    } catch (error) {
      callBack(error, "fail");
    }
  };
};

export const editUserDetails = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = new FormData();
      data.append("name", payload?.name);
      data.append("picture", payload?.picture);
      if (payload?.oldPassword) {
        data.append("old_password", payload?.oldPassword);
      }
      if (payload?.password) {
        data.append("password", payload?.password);
      }
      data.append("stripe_acc_id", payload?.stripe_acc_id);
      data.append("access_token", payload?.access_token);
      let config = {
        method: "patch",
        maxBodyLength: Infinity,
      };

      const res = await axios.post(
        `${API_URL}/user/update/${payload?.user_id}`,
        data,
        config
      );
      if (res) {
        dispatch({
          type: EDIT_USER_DETAIL,
          payload: res?.data,
        });
        callBack(res, "success");
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack(message, "fail");
    }
  };
};

export const logOutUser = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: payload?.userID,
        access_token: payload?.accessToken,
        token_type: "AccessToken",
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`${API_URL}/user/logout`, data, config);

      if (res?.data?.status === true) {
        callBack(res?.data, "success");
        localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
        localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
        localStorage.removeItem(localStoragePrefix + "screenGeniusUserRole");
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack(message, "fail");
    }
  };
};

export const userSendFeedBack = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        senderemail: payload?.email,
        userMessage: payload?.feedback,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/send-feedback`,
        data,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("failed", message);
      console.log(err, "---Error---");
    }
  };
};
