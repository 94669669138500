import React from 'react';
import { getNotionLogin } from '../../../../redux/actions/social-share';

const NotionLogin = ({ cliendID, children, callback }) => {
    const handleNotionLogin = () => {
        const notionAuthUrl = `https://api.notion.com/v1/oauth/authorize?client_id=${cliendID}&response_type=code&owner=user`;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const popup = window.open(
            notionAuthUrl,
            "Notion",
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        function handleAuthResponse(event) {
            const { code } = event?.data;
            if (code) {
                getNotionLogin(code, (type, res) => {
                    if (type === 'success') {
                        callback('success', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    } else {
                        callback('failed', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    }
                })
            } else {
                callback('failed', null);
            }
        }
        window.addEventListener("message", handleAuthResponse);
    };

    return (
        <div onClick={handleNotionLogin}>
            {children}
        </div>
    );
};

NotionLogin.defaultProps = {
    cliendID: "",
    callback: function () { },
}

export default NotionLogin;