import React, { useState } from "react";
import calendarIcon from "../../assets/images/quix-dash-calendar.png";
import deleteIcon from "../../assets/images/quix-dash-delete.png";
import { API_URL } from "../../utils/config";
import playIcon from "../../assets/images/play-black-icon.png";
import viewFileIcon from "../../assets/images/view-file-icon.png";
import pauseIcon from "../../assets/images/pause-black-icon.png";
import pdfIcon from "../../assets/images/pdf-icon.png";
import wordIcon from "../../assets/images/docs-icon.png";
import dummayImg from "../../assets/images/dummy-image.jpg";
import dateFormat from "dateformat";
import {
  checkServerURL,
  failureMessagePopup,
  sgOpenNewTab,
  successMessagePopup,
} from "../../helper/helper";
import { useDispatch } from "react-redux";
import {
  getAllSavedSnippets,
  multiRemoveSnippets,
} from "../../redux/actions/manageSavedSnippets";
import { localStoragePrefix } from "../../utils/config";

const SnippetCard = ({
  cardData,
  isStarredPage,
  isCheckedCard,
  handleChangeChecked,
  cardDataInd,
  handlePlayPause,
  currentAudioIndex,
  isPlaying,
}) => {
  const itemsThumbnailPath = cardData?.file
    ? checkServerURL(API_URL, cardData?.file)
    : dummayImg;
  const itemsType = cardData?.type && cardData?.type;
  const itemID = cardData?.id && cardData?.id;
  const itemCreateAt = cardData?.created_at && cardData?.created_at;
  const itemText = cardData?.text && cardData?.text;
  const itemAudio = cardData?.audio && cardData?.audio;

  const UserID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  const accessToken = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const dispatch = useDispatch();

  function stripHtmlAndFormat(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    const formattedText = tempDiv.innerHTML
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n')
      .replace(/<\/?[^>]+(>|$)/g, '');
    return formattedText.trim();
  }

  const [isCopyText, setCopyText] = useState(false);
  const handleCopyText = (text) => {
    if (isCopyText) return;
    const cleanedText = stripHtmlAndFormat(text);
    navigator.clipboard
      .writeText(cleanedText)
      .then(() => {
        setCopyText(true);
        setTimeout(() => {
          setCopyText(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopyText(false);
      });
  };

  const handleImageType = (type, img) => {
    switch (type) {
      case "PDF OCR":
        return { dummayIcon: pdfIcon, css: { padding: "20px" } };
      case "Word OCR":
        return { dummayIcon: wordIcon, css: { padding: "20px" } };
      default:
        return { dummayIcon: img };
    }
  };

  const handleDelete = () => {
    if (itemID) {
      const payload = {
        ids: [itemID],
        user_id: Number(UserID),
        access_token: accessToken,
      };

      dispatch(
        multiRemoveSnippets(payload, (type, res) => {
          if (type === "success") {
            successMessagePopup("Success message", "Removed successfully!");
            const payload = {
              user_id: Number(UserID),
              access_token: accessToken,
            };
            dispatch(getAllSavedSnippets(payload));
          } else if (type === "failed") {
            failureMessagePopup("Error message", res?.message);
          }
        })
      );
    }
  };

  return (
    <div className="quix-dashboard-content-item-outer" data-video-id={itemID}>
      <input
        type="checkbox"
        className="check-item-type"
        name="check-item-type"
        data-item-type="screenshots"
        data-item-id={itemID}
        data-item-starredval="0"
        data-item-trashval="0"
        onChange={() =>
          handleChangeChecked ? handleChangeChecked(itemID) : ""
        }
        checked={!isCheckedCard ? false : true}
      />
      <div className="quix-dashboard-content-item ">
        <img
          className="quix-dashboard-content-item-img"
          src={handleImageType(itemsType, itemsThumbnailPath)?.dummayIcon}
          style={{
            padding: handleImageType(itemsType, itemsThumbnailPath)?.css
              ?.padding,
            objectFit: handleImageType(itemsType, itemsThumbnailPath)?.css
              ?.objectFit,
          }}
        />
      </div>
      <div className="item-footer-info">
        <div className="item-footer-inner">
          <div className="item-title" title={itemsType}>
            {itemsType}
          </div>
          <div className="item-bottom-details">
            <div
              className="item-date"
              style={{ width: "max-content", fontSize: "12px" }}
            >
              <img src={calendarIcon} />
              <span>
                {itemCreateAt &&
                  dateFormat(itemCreateAt, "dd/mm/yyyy hh:MM TT")}
              </span>
            </div>
            <div
              className="item-date"
              style={{ width: "max-content", fontSize: "12px", float: "right" }}
            >
              {itemAudio && (
                <img
                  className={`view-snippet-audio`}
                  height={"20px"}
                  width={"20px"}
                  src={
                    cardDataInd === currentAudioIndex && isPlaying
                      ? pauseIcon
                      : playIcon
                  }
                  id={`snippet-${cardDataInd}`}
                  onClick={() => handlePlayPause(cardDataInd, itemAudio)}
                  title={
                    cardDataInd === currentAudioIndex && isPlaying
                      ? "Pause audio"
                      : "Play audio"
                  }
                />
              )}
              {itemsThumbnailPath && (
                <img
                  title="View"
                  className="view-snippets"
                  style={{ width: "30px !important" }}
                  src={viewFileIcon}
                  onClick={() => sgOpenNewTab(itemsThumbnailPath)}
                />
              )}
              <img
                title="Delete"
                className="dlt-btn-snippets"
                src={deleteIcon}
                onClick={() => handleDelete()}
              />
              {itemText && (
                <button
                  title={isCopyText ? "Copied" : "Copy"}
                  onClick={() => handleCopyText(itemText)}
                  className="btn copy-text-btn"
                  style={{ width: '50px' }}
                >
                  {isCopyText ? "Copied" : "Copy"}
                </button>
              )}
            </div>
          </div>
        </div>
        {itemText && (
          <div className="quix-card-summary-dec snippets-text">
            <div className="quix-snippets-decription">
              <div dangerouslySetInnerHTML={{ __html: itemText }} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SnippetCard;
