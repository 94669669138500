import React, { useEffect, useState } from "react";

const SlackChannelsList = ({ ChannelsList, closeModal, callback }) => {
    const intialData = {
        channel: "",
        token: ''
    };

    const channelsObj = ChannelsList?.data?.channels ? ChannelsList?.data?.channels : [];
    const firstChannelID = ChannelsList?.data ? ChannelsList?.data?.channels?.[0]?.id : null;
    const token = ChannelsList?.data?.accessToken ? ChannelsList?.data?.accessToken : null;

    useEffect(() => {
        setFormData({
            channel: firstChannelID,
            token: token
        });
    }, [token, firstChannelID]);

    const [formData, setFormData] = useState(intialData);

    const handleSubmit = () => {
        callback(formData);
    };

    return (
        <div id="quix-dashboard-popup">
            <div className="quix-dashboard-popup-inner slack-share-list">
                <div className="quix-dashboard-popup-body">
                    <div className="quix-dashboard-popup-body-inner share-details-form card border-0">
                        <div className="card-body py-0">
                            <div className="title-slack-list-popup mb-3 mt-2"><span><b>Slack Channel Selection</b></span></div>
                            <div className="quix-form-filed text-center">
                                <div className="row">
                                    <div className="mb-3 quix-form-filed text-start">
                                        <label htmlFor="quix-title" className="mb-2">
                                            Select a Channel to Connect
                                        </label>
                                        <select
                                            className="form-select"
                                            onChange={(e) =>
                                                setFormData({ ...formData, channel: e.target.value })
                                            }
                                        >
                                            {channelsObj?.length > 0 &&
                                                channelsObj?.map((item, ind) => {
                                                    return (
                                                        <option
                                                            key={ind}
                                                            name={item?.name}
                                                            value={item?.id}
                                                        >
                                                            {item?.name}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    </div>
                                    <div className="quix-form-filed text-center">
                                        <button
                                            className="btn form-action-button form-cancel-button"
                                            onClick={() => closeModal(false)}
                                        >
                                            cancel
                                        </button>
                                        <button
                                            className="btn form-action-button form-save-button"
                                            onClick={() => handleSubmit()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SlackChannelsList.defaultProps = {
    ChannelsList: {},
    closeModal: function () { },
};

export default SlackChannelsList;
