import {
  GET_SCREENSHOTS,
  GET_STARRED_SCREENSHOTS,
  NOT_FOUND_SCREENSHOT,
  UPDATE_STARRED_SCREENSHOT,
  RENAME_CARD,
  SEND_BY_EMAIL,
  REMOVE_CARD,
  NOT_FOUND_STARRED_SCREENSHOT,
  SHORT_SCREENSHOT,
  SHORT_STARRED_SCREENSHOT,
  UPLOAD_SCREENSHOT,
  LOADING_SCREENSHORT,
  LOADING_STARRED_SCREENSHOT,
  SEARCH_SCREENSHOTS,
  SCREENSHOT_DETAILS,
  SEARCH_STARRED_SCREENSHOTS,
} from "./type";
import axios from "axios";
import { API_URL } from "../../utils/config";

// withCredentials
axios.withCredentials = false;

export const manageScreenShots = (userID, authToken) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_SCREENSHORT,
    });
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/screenshots/list`, data, config);
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_SCREENSHOT,
            payload: null,
          });
        } else {
          dispatch({
            type: GET_SCREENSHOTS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const manageStarredScreenShorts = (userID, authToken) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_STARRED_SCREENSHOT,
    });
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/starred-list`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_STARRED_SCREENSHOT,
            payload: null,
          });
        } else {
          dispatch({
            type: GET_STARRED_SCREENSHOTS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const updateStarredScreenShot = (payload, callBack) => {
  return async (dispatch) => {
    try {
      payload?.id &&
        payload?.id?.map(async (id) => {
          let data = JSON.stringify({
            is_starred: payload?.status,
            id: id,
            access_token: payload?.access_token,
            user_id: payload?.user_id,
          });
          let config = {
            maxBodyLength: Infinity,
            headers: {
              "Content-Type": "application/json",
            },
          };
          try {
            const res = await axios.post(
              `${API_URL}/screenshots/starred`,
              data,
              config
            );
            if (res?.data?.status === true) {
              dispatch({
                type: UPDATE_STARRED_SCREENSHOT,
                payload: res?.data,
              });
              callBack("success", res);
            }
          } catch (error) {
            let message = error?.response?.data;
            callBack("fail", message);
          }
        });
    } catch (err) {
      callBack("fail", "something went wrong!");
    }
  };
};

export const renameScreenShot = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        name: payload?.name,
        title: payload?.title,
        summary: payload?.summary,
        tags: payload?.tags,
        id: payload?.id,
        access_token: payload?.access_token,
        user_id: payload?.user_id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/screenshots/rename`,
        data,
        config
      );
      if (res?.data?.status === true) {
        dispatch({
          type: RENAME_CARD,
          payload: res?.data,
        });
        callBack("success", res);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("fail", message);
    }
  };
};

export const shareByEmailScreenShot = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        sid: payload?.id,
        toname: payload?.toname,
        emailId: payload?.emailId,
        userMessage: payload?.userMessage,
        access_token: payload?.access_token,
        user_id: payload?.userId,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/send-email`,
        data,
        config
      );
      if (res) {
        dispatch({
          type: SEND_BY_EMAIL,
          payload: res?.data,
        });
        callBack("success");
      }
    } catch (err) {
      callBack("failed");
      console.log(err, "---Error---");
    }
  };
};

export const removeScreenShot = (payload, callBack) => {
  return (dispatch) => {
    try {
      if (window.confirm("Are you sure?")) {
        payload?.id &&
          payload?.id?.map(async (id) => {
            let data = JSON.stringify({
              id: id,
              access_token: payload?.access_token,
              user_id: payload?.user_id,
            });
            let config = {
              maxBodyLength: Infinity,
              headers: {
                "Content-Type": "application/json",
              },
            };
            try {
              const res = await axios.post(
                `${API_URL}/screenshots/delete`,
                data,
                config
              );
              if (res?.data?.status === true) {
                dispatch({
                  type: REMOVE_CARD,
                  payload: res?.data,
                });
                callBack("success", res);
              }
            } catch (err) {
              let message = err?.response?.data;
              callBack("fail", message);
            }
          });
      }
    } catch (err) {
      callBack("fail", "Something went wrong!");
    }
  };
};

// multi delete
export const multiRemoveScreenShot = (payload, callBack) => {
  return async (dispatch) => {
    try {
      if (window.confirm("Are you sure?")) {
        let data = JSON.stringify({
          ids: payload?.id,
          access_token: payload?.access_token,
          user_id: payload?.user_id,
        });
        let config = {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        try {
          const res = await axios.delete(
            `${API_URL}/screenshots/delete`,
            config
          );
          if (res?.data?.status === true) {
            dispatch({
              type: REMOVE_CARD,
              payload: res?.data,
            });
            callBack("success", res);
          }
        } catch (err) {
          let message = err?.response?.data;
          callBack("fail", message);
        }
      }
    } catch (err) {
      callBack("fail", "Something went wrong!");
    }
  };
};

export const shortingScreenShot = (userID, authToken, type) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/list?sort=${type}`,
        data,
        config
      );

      if (res) {
        dispatch({
          type: SHORT_SCREENSHOT,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const shortingStarredScreenShot = (userID, authToken, type) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/starred-list?sort=${type}`,
        data,
        config
      );

      if (res) {
        dispatch({
          type: SHORT_STARRED_SCREENSHOT,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const uploadScreenShot = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = new FormData();
      data.append("name", payload?.name);
      data.append("user_id", payload?.user_id);
      data.append("file_size", payload?.file_size);
      data.append("screenshot", payload?.file);
      data.append("access_token", payload?.access_token);
      data.append("title", payload?.title);
      data.append("summary", payload?.summary);
      data.append("tags", payload?.tags);

      const res = await axios.post(`${API_URL}/screenshots/upload`, data);
      if (res) {
        dispatch({
          type: UPLOAD_SCREENSHOT,
          payload: res?.data,
        });
        callBack("success", res);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("fail", message);
    }
  };
};

// send feed back
export const sendFeedBack = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        senderEmail: payload?.senderEmail,
        whyUninstall: payload?.whyUninstall,
        userMessage: payload?.userMessage,
        otherTool: payload?.otherTool,
        whatToImprove: payload?.whatToImprove,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/screenshots/send-feedback`,
        data,
        config
      );

      if (res) {
        dispatch({
          type: SEND_BY_EMAIL,
          payload: res?.data,
        });
        callBack("success");
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack("failed", message);
      console.log(error);
    }
  };
};

export const editScreenshotTitleAndSummary = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        title: payload?.title,
        summary: payload?.summary,
        id: payload?.id,
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/screenshots/add-summary`,
        data,
        config
      );
      if (res?.data?.status) {
        dispatch({
          type: "",
          payload: "",
        });
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error.response.data;
      callBack("failed", message);
    }
  };
};

export const addWaterMark = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = new FormData();
      data.append("user_id", payload?.user_id);
      data.append("access_token", payload?.access_token);
      data.append("image_screenshot", payload?.image_screenshot);
      data.append("screenshot_transparency", payload?.screenshot_transparency);
      data.append(
        "text_screenshot",
        `{"fontSize": "${payload?.text_screenshot?.fontSize}","fontColor":"${payload?.text_screenshot?.fontColor}","text":"${payload?.text_screenshot?.text}"}`
      );
      data.append(
        "position_screenshot",
        `{"position":"${payload?.position_screenshot}","x":"${payload?.screenshot_border_x}","y":"${payload?.screenshot_border_y}"}`
      );
      data.append("image_videos", payload?.image_videos);
      data.append(
        "text_videos",
        `{"fontSize": "${payload?.text_videos?.fontSize}","fontColor":"${payload?.text_videos?.fontColor}","text":"${payload?.text_videos?.text}"}`
      );
      data.append(
        "position_videos",
        `{"position":"${payload?.position_videos}","x":"${payload?.video_border_x}","y":"${payload?.video_border_y}"}`
      );
      data.append("videos_transparency", payload?.videos_transparency);
      let config = {
        maxBodyLength: Infinity,
      };

      const res = await axios.post(
        `${API_URL}/waterMarkSetting/add-waterMark-setting`,
        data,
        config
      );
      if (res?.data?.status) {
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack("failed", message);
    }
  };
};

export const manageSearchScreenShots = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_SCREENSHORT,
    });
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/list?search=${payload?.search_text}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_SCREENSHOT,
            payload: null,
          });
        } else {
          dispatch({
            type: SEARCH_SCREENSHOTS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const manageScreenshotDetails = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/screenshots/details?sid=${payload?.id}`,
        config
      );

      if (res) {
        dispatch({
          type: SCREENSHOT_DETAILS,
          payload: res?.data?.data,
        });
        callBack("success", res?.data?.data);
      }
    } catch (error) { }
  };
};

export const manageSearchStarredScreenShots = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_SCREENSHORT,
    });
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/screenshots/starred-list?search=${payload?.search_text}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_STARRED_SCREENSHOT,
            payload: null,
          });
        } else {
          dispatch({
            type: SEARCH_STARRED_SCREENSHOTS,
            payload: res?.data,
          });
        }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const manageUrlSummary = (payload, callBack) => {
  return async () => {
    try {
      let data = JSON.stringify({
        url: payload?.url,
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/integration/url-summary`,
        data,
        config
      );

      if (res?.data?.status === "success") {
        callBack("success", res?.data);
      }
    } catch (error) {
      let message = error?.response?.data;
      callBack("failed", message);
    }
  };
};
