import React from "react";
import { getMicrosoftLogin } from "../../../../redux/actions/social-share";

const MicroSoftLogin = ({ clientId, redirect_Url, children, callback }) => {
    const handleOnenoteLogin = () => {
        const clientID = clientId;
        const redirectUri = redirect_Url;
        const scope = 'openid profile User.Read Notes.ReadWrite.All offline_access Sites.ReadWrite.All Files.ReadWrite.All Group.ReadWrite.All Chat.ReadWrite';
        const OneNoteAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientID}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=${scope}&state=12345`;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popup = window.open(
            OneNoteAuthUrl,
            "Notion",
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        const handleAuthResponse = async (event) => {
            const { code } = event.data;
            if (code) {
                const payload = {
                    code: code,
                    type: "oneNote",
                };
                getMicrosoftLogin(payload, (type, res) => {
                    if (type === "success") {
                        callback("success", res?.data);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    } else if (type === "failed") {
                        callback("failed", res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    }
                });
            }
        };
        window.addEventListener("message", handleAuthResponse);
    };

    return <div onClick={handleOnenoteLogin}>{children}</div>;
};

export default MicroSoftLogin;
