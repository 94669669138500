import React, { useEffect, useRef } from 'react';

const AdSenseAd = ({ client, slot }) => {
  const adRef = useRef(null);

  useEffect(() => {
    const adsenseScript = document.createElement('script');
    adsenseScript.async = true;
    adsenseScript.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`;
    adsenseScript.crossOrigin = "anonymous";
    document.body.appendChild(adsenseScript);

    const ampAdScript = document.createElement('script');
    ampAdScript.async = true;
    ampAdScript.setAttribute('custom-element', 'amp-ad');
    ampAdScript.src = "https://cdn.ampproject.org/v0/amp-ad-0.1.js";
    document.body.appendChild(ampAdScript);

    adsenseScript.onload = () => {
      if (adRef.current && adRef.current.children.length === 0) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    };

    return () => {
      document.body.removeChild(adsenseScript);
      document.body.removeChild(ampAdScript);
    };
  });

  return (
    <>
      <div>
        <ins
          ref={adRef}
          className="adsbygoogle"
          style={{ display: 'block', minWidth: "400px", width: "100%", height: "150px" }}
          data-ad-client={client}
          data-ad-slot={slot}
          // data-ad-format="auto"
          data-ad-format="horizontal"
          data-full-width-responsive="true"
        ></ins>
      </div>
      <amp-ad
        width="100vw"
        height="320"
        type="adsense"
        data-ad-client={client}
        data-ad-slot={slot}
        data-auto-format="rspv"
        data-full-width=""
      >
        <div overflow=""></div>
      </amp-ad>
    </>
  );
};


export default AdSenseAd;
