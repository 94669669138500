import React, { useEffect } from "react";
import AdminLayout from "../../layout/adminLayout";
import ExtansionsReport from "../../components/Charts/ExtansionsReport";
import earningIcon from "../../assets/images/dashboard/coins-icon.png";
import usersIcon from "../../assets/images/dashboard/users-icon.png";
import screenshotsIcon from "../../assets/images/dashboard/camera-icon.png";
import recordingsIcon from "../../assets/images/dashboard/video-icon.png";
import { connect, useDispatch } from "react-redux";
import ExtansionsAllReport from "../../components/Charts/ExtansionsAllReport";
import OcrReportTable from "./components/OcrReportTable";
import InfoCard from "./components/InfoCard";
import { localStoragePrefix } from "../../../utils/config";
import { getStatisticsListAdmin } from "../../../redux/actions/admin/statistics";
import StatusReports from "./components/StatusReports";

const AdminDashboard = ({ statistics }) => {
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStatisticsListAdmin(userID));
  }, []);
  const statisticsData = statistics?.data ? statistics?.data : [];
  const totalUsers = statisticsData?.total_user ?? 0;
  const totalScreenshot = statisticsData?.total_screenshots ?? 0;
  const totalVideos = statisticsData?.total_video ?? 0;
  const totalMembershipPlan = statisticsData?.total_price ?? 0;

  return (
    <div className="quix-admin-dashboard">
      {/* admin layout */}
      <AdminLayout pageTitle={"admin-dashboard"}>
        <div className="quix-admin-dashboard-content">
          <div className="row report-card-outer mb-3">
            <InfoCard
              iconSrc={earningIcon}
              title={"Earnings"}
              counting={totalMembershipPlan}
            />
            <InfoCard
              iconSrc={usersIcon}
              title={"Users"}
              counting={totalUsers}
            />
            <InfoCard
              iconSrc={screenshotsIcon}
              title={"Screenshots"}
              counting={totalScreenshot}
            />
            <InfoCard
              iconSrc={recordingsIcon}
              title={"Videos"}
              counting={totalVideos}
            />
          </div>
          <div className="status-reports">
            <StatusReports />
          </div>
          <div className="extension-request-details row">
            <ExtansionsReport />
            <ExtansionsAllReport />
          </div>
          <div className="extension-request-table mt-4">
            <OcrReportTable />
          </div>
        </div>
      </AdminLayout>
      {/* admin layout end*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  statistics: state?.admin_statistics?.list,
});

export default connect(mapStateToProps, { getStatisticsListAdmin })(
  AdminDashboard
);
