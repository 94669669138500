import React from 'react';
import { loginWithLinkedIn } from '../../../../redux/actions/authentication';

const LinkedInLogin = ({ cliendID, redirect_Url, scope, callback, children }) => {
    const linkedin_client_id = cliendID;
    const callBackUrl = redirect_Url;
    const linkedinScope = scope;
    const state = "xas1xcewe322dszdasqwe";

    const handleLinkedInLogin = () => {
        const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${state}&scope=${linkedinScope}&client_id=${linkedin_client_id}&redirect_uri=${encodeURIComponent(callBackUrl)}`;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const popup = window.open(
            linkedinAuthUrl,
            "linkedIN",
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        function handleAuthResponse(event) {
            const { code } = event?.data;
            if (code) {
                loginWithLinkedIn(code, (type, res) => {
                    if (type === 'success') {
                        callback('success', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    } else if (type === 'failed') {
                        callback('failed', res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    }
                })
            }
        }
        window.addEventListener("message", handleAuthResponse);
    };

    return (
        <div onClick={handleLinkedInLogin}>
            {children}
        </div>
    );
};

LinkedInLogin.defaultProps = {
    cliendID: "",
    redirect_Url: "",
    scope: '',
    callback: function () { },
}


export default LinkedInLogin;