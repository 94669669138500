import axios from "axios";
import { LOADING_OCR_REPORT, OCR_REPORT, NOT_FOUND_OCR_REPORT } from "../../actions/type";
import { API_URL } from "../../../utils/config";

// OCR report
export const dashboardOCRreportAdmin = (count) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_OCR_REPORT
            })
            let config = {
                maxBodyLength: Infinity,
            };
            const res = await axios.get(
                `${API_URL}/ocr/ocr-report?count=${count}`,
                config
            );
            if (res?.data?.status === true) {
                if (res?.data?.data?.length <= 0) {
                    dispatch({
                        type: NOT_FOUND_OCR_REPORT,
                    })
                } else {
                    dispatch({
                        type: OCR_REPORT,
                        payload: res?.data,
                    });
                }
            }
        } catch (error) {
            console.log(error, "===>");
        }
    };
};
