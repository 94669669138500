import React from "react";
import { getSlackLogin } from "../../../../redux/actions/social-share";

const SlackLogin = ({ clientId, redirect_Url, children, callback }) => {
    const handleSlackLogin = () => {
        const clientID = clientId;
        const redirectUri = encodeURIComponent(redirect_Url);
        const scope =
            "channels:join,users:read,chat:write,files:write,channels:read,groups:read,mpim:read,im:read";
        const state = "";
        const granularBotScope = 1;
        const singleChannel = 0;
        const team = "";

        const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientID}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}&granular_bot_scope=${granularBotScope}&single_channel=${singleChannel}&team=${team}`;
        const width = 600;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        const popup = window.open(
            slackAuthUrl,
            "Notion",
            `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        const handleAuthResponse = async (event) => {
            const { code } = event.data;
            if (code) {
                getSlackLogin(code, (type, res) => {
                    if (type === "success") {
                        callback("success", res?.data);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    } else if (type === "failed") {
                        callback("failed", res);
                        window.removeEventListener("message", handleAuthResponse);
                        popup.close();
                    }
                });
            }
        };
        window.addEventListener("message", handleAuthResponse);
    };

    return <div onClick={handleSlackLogin}>{children}</div>;
};

export default SlackLogin;
